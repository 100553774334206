import React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';

const DateFilter = ({ fromDate, toDate, onDateRangeChange, minDate, maxDate }) => {
    const dateRange = [
        fromDate ? dayjs(fromDate, 'YYYY-MM-DD') : null,
        toDate ? dayjs(toDate, 'YYYY-MM-DD') : null,
    ];

    const handleDateRangeChange = (newValue) => {
        const [start, end] = newValue;
        const formattedStart = start ? start.format('YYYY-MM-DD') : null;
        const formattedEnd = end ? end.format('YYYY-MM-DD') : null;
        onDateRangeChange({ fromDate: formattedStart, toDate: formattedEnd });
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <DateRangePicker
                    localeText={{ start: 'From:', end: 'To:' }}
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    renderInput={(startProps, endProps) => (
                        <>
                            <TextField fullWidth variant="filled" {...startProps} />
                            <TextField fullWidth variant="filled" {...endProps} />
                        </>
                    )}
                    minDate={minDate ? dayjs(minDate, 'YYYY-MM-DD') : undefined}
                    maxDate={maxDate ? dayjs(maxDate, 'YYYY-MM-DD') : undefined}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateFilter;